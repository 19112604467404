<template>
  <div>
    <Notify ref="myNotify" :details="message"></Notify>
    <v-row>
      <v-col lg="10" class="mx-auto">
        <v-card
          class="card-shadow border-radius-xl"
          v-if="detail_order.order != null"
        >
          <Loading v-if="loading"></Loading>
          <div class="px-4 pt-4">
            <div class="">
              <div>
                <h6 class="text-h6 text-typo font-weight-bold mb-2">
                  {{ $t("order.Order Details") }}
                </h6>
                <div>
                  <p class="text-sm text-body mb-0">
                    <v-row>
                      <v-col md="6">
                        <span class="mr-3"
                          >{{ $t("order.Order ID") }}.
                          <b>{{ detail_order.order.id }}</b>
                        </span>
                        <span
                          >{{ $t("order.from") }}
                          <b>{{ detail_order.order.created_at }}</b></span
                        >
                      </v-col>
                      <v-col md="6" :class="classLang">
                        <span class="mr-3"
                          >{{ $t("order.status") }}.
                          <b :class="`badge bg-${color_name}`">{{
                            detail_order.order.statuses.status
                          }}</b>
                        </span>
                      </v-col>
                    </v-row>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 pb-4">
            <hr class="horizontal dark my-6" />
            <v-row>
              <v-col v-if="detail_order.order.cycles" lg="6" md="4">
                <div
                  class="
                    d-flex
                    flex-column
                    bg-gray-100
                    pa-6
                    border-radius-lg
                    mt-3
                  "
                >
                  <h6 class="text-typo font-weight-bold mb-4 text-sm">
                    {{ $t("cycle.Cycle") }} # {{ detail_order.order.cycles.id }}
                  </h6>
                  <span class="mb-2 text-body text-xs"
                    >{{ $t("cycle.title") }}
                    <span class="text-dark font-weight-bold ms-2">{{
                      detail_order.order.cycles.name_lang
                    }}</span></span
                  >
                  <span class="mb-2 text-body text-xs"
                    >{{ $t("cycle.Description") }}
                    <span class="text-dark font-weight-bold ms-2">{{
                      detail_order.order.cycles.description_lang
                    }}</span></span
                  >

                  <span class="mb-2 text-body text-xs"
                    >{{ $t("cycle.Start") }}
                    <span class="text-dark font-weight-bold ms-2">{{
                      detail_order.order.cycles.start
                    }}</span></span
                  >

                  <span class="mb-2 text-body text-xs"
                    >{{ $t("cycle.Expire") }}
                    <span class="text-dark font-weight-bold ms-2">{{
                      detail_order.order.cycles.expire
                    }}</span></span
                  >
                </div>
              </v-col>
              <!-- <v-col v-if="detail_order.order.customers.users" lg="6" md="4">
                <h6 class="text-h6 text-typo font-weight-bold mb-4">
                  {{ $t("order.Customer Information") }}
                </h6>
                <div
                  class="d-flex flex-column bg-gray-100 pa-6 border-radius-lg"
                >
                  <h6 class="text-typo font-weight-bold mb-4 text-sm">
                    {{ detail_order.order.customers.users.first_name }}

                    {{ detail_order.order.customers.users.last_name }}
                  </h6>
                  <span class="mb-2 text-body text-xs"
                    >{{ $t("auth.Email Address") }}:
                    <span class="text-dark ms-2 font-weight-bold">{{
                      detail_order.order.customers.users.email
                    }}</span></span
                  >
                  <span class="text-xs text-body"
                    >{{ $t("auth.Phone Number") }}:
                    <span class="text-dark ms-2 font-weight-bold">{{
                      detail_order.order.customers.users.phone
                    }}</span></span
                  >
                </div>
              </v-col> -->
              <v-col lg="6" md="4" class="ms-auto">
                <h6 class="text-h6 text-typo font-weight-bold mb-3">
                  {{ $t("cycle.General Information") }}
                </h6>
                <div
                  v-if="detail_order.order.stages"
                  class="d-flex flex-column bg-gray-100 pa-6 border-radius-lg"
                >
                  <h6 class="text-typo font-weight-bold mb-4 text-sm">
                    {{ $t("stage.Stages") }} #
                    {{ detail_order.order.stages.id }}
                  </h6>
                  <span class="mb-2 text-body text-xs"
                    >{{ $t("group.Name") }}
                    <span class="text-dark font-weight-bold ms-2">{{
                      detail_order.order.stages.name_lang
                    }}</span></span
                  >
                </div>

                <div class="mt-4" v-if="detail_order.order.customers.users">
                  <h6 class="text-h6 text-typo font-weight-bold mb-4">
                    {{ $t("order.Customer Information") }}
                  </h6>
                  <div
                    class="d-flex flex-column bg-gray-100 pa-6 border-radius-lg"
                  >
                    <h6 class="text-typo font-weight-bold mb-4 text-sm">
                      {{ detail_order.order.customers.users.first_name }}

                      {{ detail_order.order.customers.users.last_name }}
                    </h6>
                    <span class="mb-2 text-body text-xs"
                      >{{ $t("auth.Email Address") }}:
                      <span class="text-dark ms-2 font-weight-bold">{{
                        detail_order.order.customers.users.email
                      }}</span></span
                    >
                    <span class="text-xs text-body"
                      >{{ $t("auth.Phone Number") }}:
                      <span class="text-dark ms-2 font-weight-bold">{{
                        detail_order.order.customers.users.phone
                      }}</span></span
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
            <hr class="horizontal dark my-6" />
            <v-row>
              <v-col lg="12" md="4">
                <h6 class="text-h6 text-typo font-weight-bold mb-4">
                  {{ $t("order.Stage order details") }}
                </h6>
                <div>
                  <div class="box-row">
                    <div
                      class="box"
                      v-for="(stage, index) in detail_order.order.cycles.stages"
                      @click="value = stage.id"
                      :key="index"
                      :class="[value == stage.id ? 'active' : '']"
                    >
                      <!-- {{ value }} {{ stage.id }} -->
                      <div class="box-button">
                        <div class="box-tag box-tag-left"></div>
                        <div class="box-icon">
                          {{ stage.pivot.sort }}
                        </div>
                        <div class="box-tag box-tag-right"></div>
                      </div>
                      <span class="box-label">{{ stage.name }}</span>
                    </div>
                  </div>

                  <v-card
                    v-if="inquiries.length > 0"
                    class="mb-12 mt-5"
                    elevation="5"
                    outlined
                  >
                    <v-card-text>
                      <v-row class="mt-2">
                        <v-col
                          v-for="(inqery, index) in inquiries1"
                          :key="index"
                          sm="6"
                          cols="12"
                        >
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >{{ inqery.inquery_lang }}</label
                          >
                          <div v-if="inqery.inputs.type == 'file'">
                            <a
                              style="text-decoration: none"
                              :href="$baseURL + inqery.pivot.answer"
                              target="_blank"
                            >
                              <!-- @click.prevent="downloadItem(inqery.pivot.answer)" -->
                              <v-chip
                                label
                                color="gray"
                                class="py-1 pl-6 pr-10 my-0"
                              >
                                <v-icon size="18" class="rotate-180"
                                  >fas fa-file-pdf text-lg me-1</v-icon
                                >
                                {{ $t("ticket.file") }}
                              </v-chip>
                            </a>
                          </div>
                          <div v-else class="form-controle input-order">
                            {{
                              inqery.pivot.answer != null
                                ? inqery.pivot.answer
                                : "-"
                            }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>
                <br />
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Notify from "../Components/Old/Notify.vue";
import Loading from "../Components/Loading.vue";
import Header from "../Components/Header.vue";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("order");
export default {
  name: "Order-Details",
  components: {
    Loading,
    Notify,
    Header,
  },
  data() {
    return {
      activeTab: "",
      value: null,
      loading: true,
      loader_form: false,
      detail_order: {},
      inquiries: [],
      color: "",
      message: {
        msg: null,
        type: null,
      },
      data: [
        {
          col: "12",
          type: "text",
          label: "Name",
          error: null,
          value_text: "name",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
      ],
      steps: "",
    };
  },
  computed: {
    ClassAvtice(id) {
      return this.value == id ? "active" : "";
    },
    ClassComplete(val) {
      return val <= this.detail_order.order.stage_id ? "complete" : "";
    },
    classLang() {
      return {
        "text-left": this.$vuetify.rtl,
        "text-right": !this.$vuetify.rtl,
      };
    },
    inquiries1() {
      return this.inquiries.filter((v) => v.pivot.stage_id == this.value);
    },
    color_name() {
      if (this.detail_order.order.statuses.id == "1") {
        return "warning";
      } else if (this.detail_order.order.statuses.id == "2") {
        return "info";
      } else if (this.detail_order.order.statuses.id == "3") {
        return "success";
      } else if (this.detail_order.order.statuses.id == "4") {
        return "danger";
      } else if (this.detail.order.statuses.id == "5") {
        return "orange";
      } else {
        return "secondary";
      }
    },
  },
  methods: {
    ...mapActions(["getOrderAction"]),
    downloadItem(item) {
      axios({
        url: this.$baseURL + item,
        responseType: "blob",
        method: "get",
      }).then(
        (response) => {
          // console.log(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file_");
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          this.message = {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          };
        }
      );
    },
  },
  mounted() {
    document.title = this.$i18n.t("order.Order Details");
    this.getOrderAction(this.$route.params.id).then(
      (order) => {
        this.loading = false;
        this.detail_order = order.data;
        console.log(this.detail_order);
        this.value = this.detail_order.order.stage_id;
        this.inquiries = this.detail_order.order.inquiries;
        this.steps = this.detail_order.order.cycles.stages.length;
      },
      (error) => {
        console.log(error);
        this.loading = false;
        this.message = {
          msg: this.$i18n.t("general.there is problem"),
          type: "Danger",
        };
        //
      }
    );
  },
};
</script>
<style scoped lang="scss">
$medium: 48px;
$small: 16px;
.box-row {
  display: flex;
  justify-content: space-between;
  // width: 80vw;
  width: 100%;
  margin: auto;
}

.box-button {
  display: flex;
  align-items: center;
}

.box-label {
  text-align: center;
}

.box {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.box-icon {
  width: $medium;
  height: $medium;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  border: 4px solid #ccc;
  cursor: pointer;
}
.box.active .box-icon {
  border: 4px solid #20cdbd;
  color: #20cdbd;
}
@media (max-width: 480px) {
  .box {
    width: $small;
    height: $small;
  }

  .box-icon {
    width: $small;
    height: $small;
  }

  svg {
    width: $small;
    height: $small;
  }

  body {
    font-size: 0.8em;
  }
}

.box-tag {
  flex-grow: 1;
  min-width: 20px;
  height: 4px;
  background-color: #ccc;
}
.box.active .box-tag {
  background-color: #20cdbd;
}

.box:first-child .box-tag-left {
  background-color: white;
}

.box:last-child .box-tag-right {
  background-color: white;
}
</style>
